import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * 画面のローディング処理サービス
 */
@Injectable()
export class LoadingService {
    /** ローディングフラグ */
    protected isLoad: boolean = false;
    /** ローディングステータスObserver */
    protected loadConditionStatus: Subject<boolean> = new Subject<boolean>();

    /**
     * ローディングフラフを初期化
     */
    reset() {
        this.isLoad = false;
    }

    /**
     * ローディングフラグを取得
     */
    getLoadingStatus(): boolean {
        return this.isLoad;
    }

    /**
     * ローディングステータスObserverを連携する
     */
    setLoadConditionStatus() {
        this.isLoad = true;
        this.loadConditionStatus.next(true);
    }

    startLoading() {
      this.isLoad = true;
      this.loadConditionStatus.next(true);
    }

    stopLoading() {
      this.isLoad = false;
      this.loadConditionStatus.next(false);
    }

    /**
     * ローディングステータスObserverを取得
     */
    getLoadConditionStatus() {
        return this.loadConditionStatus.asObservable();
    }
}
