import { Component, OnDestroy, OnInit, Injectable, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ScenarioConfigService } from '../../@core/data/scenario-config.service';
import { UserService } from '../../@core/data/users.service';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap'; // edited by EMK
import { IDropdownSettings } from 'ng-multiselect-dropdown'; // added ng-dropdown-setting
import { LoadingService } from 'app/@core/data/loading.service';
import { ANIMATION_TYPES } from 'ngx-loading';

/* Edited by EMK Start */
const I18N_VALUES = {
  ja: {
    weekdays: ['月', '火', '水', '木', '金', '土', '日'],
    months: [
      '1月',
      '2月',
      '3月',
      '4月',
      '5月',
      '6月',
      '7月',
      '8月',
      '9月',
      '10月',
      '11月',
      '12月',
    ],
  },
};

const PrimaryWhite = '#ffffff';
const SecondaryGrey = '#ccc';

@Injectable()
export class I18n {
  language = 'ja';
}

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
  constructor(private _i18n: I18n) {
    super();
  }
  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}
/* Edited by EMK End */
@Component({
  selector: 'ngx-scenario-configs',
  templateUrl: './scenario-configs.component.html',
  styleUrls: ['./scenario-configs.component.scss'],
  providers: [
    NgbModalConfig,
    NgbModal,
    I18n,
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
  ],
})
export class ScenarioConfigsComponent implements OnInit, OnDestroy {

  dropdownSettings: IDropdownSettings = {};   // ドロップダウン設定
  subscriptions: any = []; // Subscribeオブジェクト一覧

  isLoading: boolean = false; // ページローディングフラグ
  loadingConfig: any = {}; // ローディング処理設定
  primaryColour = PrimaryWhite; // ローディングアニメーションの色
  secondaryColour = SecondaryGrey; // ローディングアニメーションの色

  role_id; // ログインユーザーの権限
  plan_id; // plan id

  scenarioConfigs: any = []; // シナリオ一覧のデータを格納するリスト
  page = 1; // ページャーのページ番号
  pageSize = 10; // ページャーが１ページに表示するデータの数

  isSubmitted = false; // モーダルのSubmitボタン押下のフラグ
  modalMessage: string = ''; // モーダルに表示するメッセージ
  addScenarioData = new FormData(); // モーダルの登録・更新時に送信するフォームデータ

  modalDisabled: boolean = false; // モーダルのオープンの制御フラグ
  submitDisabled: boolean = false; // モーダルの登録・更新ボタンの制御フラグ

  // userGroups
  userGroups: any = []; // 検索エリアのグループを格納するリスト
  selectedGroups: any[] = []; // 選択したグループオブジェクトを保持する配列

  // site-name
  siteNames: any = []; // サイト名一覧を格納するリスト
  selectedSites: any[] = [];  // 選択したサイトを保持する配列

  // category
  categories: any = []; // カテゴリー一覧を格納するリスト
  selectedCategories: any[] = []; // 選択したカテゴリを保持する配列

  runResults: any = []; // 実行結果一覧のデータを格納するリスト

  createInsantQueueResults: any = []; // Instantキュー作成の結果

  scenarioCreationId: any;

  // シナリオ検索フォーム　Validation設定
  searchScenarioForm = this.formBuilder.group({
    searchScenarioName: [''],
    searchGroupId: ['0'],
    searchSiteName: ['0'],
    searchCategory: ['0'],
    searchRunscenario: ['1'],
  });

  // シナリオ登録画面フォーム
  addScenarioForm;

  // シナリオ編集画面フォーム
  editScenarioForm;

  // 実行結果確認画面検索フォーム
  searchRunResultForm;

  constructor(
    protected configService: ScenarioConfigService,
    protected userService: UserService,
    public formBuilder: FormBuilder,
    config: NgbModalConfig,
    private modalService: NgbModal,
    protected loadingService: LoadingService,
    private cdr: ChangeDetectorRef,
  ) {
    this.loadingConfig = {
      animationType: ANIMATION_TYPES.threeBounce,
      fullScreenBackdrop: true,
      primaryColour: this.primaryColour,
      secondaryColour: this.secondaryColour,
      tertiaryColour: this.primaryColour,
    };
    // モーダルの設定
    config.backdrop = 'static';
    config.keyboard = false;
    config.windowClass = 'configs-modal';
  }

  ngOnInit(): void {

    this.userService.getUsers().subscribe((user) => {
      this.role_id = user.role_id;

      if (this.role_id > 4) {
        // ログインしているユーザーの権限がGuneralUser以下の場合はダッシュボードに遷移する
        location.href = '/dashboard/synthetic#/pages/scenario-summary';
      } else {
        // ログインしているユーザーの権限がEngineer以上の場合は画面の表示に必要な情報を取得する

        // 検索エリアのグループのリストを取得
        this.configService.getUserGroups().subscribe((userGroups) => {
          this.userGroups = userGroups;
        });

        // ローディングの状況について
        this.loadConditionStatus();

        // サイト名とカテゴリーを取得
        this.getScenarioOptions();

        // シナリオ一覧初期表示
        this.searchScenarioConfigs();
      }
    });

    // 検索エリアのグループのリストを取得
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'すべて選択',
      unSelectAllText: 'すべて選択解除',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
  }

  /**
   * ローディングの状況についてコンポーネントで読み込むする
   */
  loadConditionStatus(): void {
      this.subscriptions.push(
        this.loadingService.getLoadConditionStatus().subscribe(res => {
          this.isLoading = res;
          this.cdr.detectChanges();
      })
    );
  }

  /**
   * シナリオ登録・更新モーダルに表示するサイト名とカテゴリーを取得する
   */
  getScenarioOptions(): void {
    // サイト名のリストを取得する
    this.configService.getScenarioSiteNames().subscribe((siteNames) => {
      this.siteNames = siteNames;
    });

    // カテゴリーのリストを取得する
    this.configService.getScenarioCategories([]).subscribe((categories) => {
      this.categories = categories;
    });
  }

  // 1.グループ
  groupOnItemSelect(groupItem: any) {
    const alreadySelected = this.selectedGroups.some(group => group.id === groupItem.id);
    if (!alreadySelected) {
      this.selectedGroups.push(groupItem);
    }
    this.updateSearchGroupId();
  }

  // 2.グループ
  groupOnSelectAll(groupItems: any) {
    this.selectedGroups = groupItems; // selecting all groups
    this.updateSearchGroupId(); // Update form values
  }

  // 3.グループ
  groupOnItemUnSelect(groupItem: any) {
    this.selectedGroups = this.selectedGroups.filter(group => group.id !== groupItem.id);
    this.searchScenarioForm.patchValue({ searchGroupId: this.selectedGroups });
  }

  // 4.グループ
  clearGroupSelections() {
    this.selectedGroups = [];
    this.updateSearchGroupId();
  }

  // 5.グループ
  private updateSearchGroupId() {
    const selectedIds = this.selectedGroups.map(group => group.id);
    this.searchScenarioForm.patchValue({
      searchGroupId: selectedIds,
    });
  }

  // 1. サイト
  siteOnItemSelect(siteOnItem: any) {
    const alreadySiteSelected = this.selectedSites.some(site => site.id === siteOnItem.id);
    if (!alreadySiteSelected) {
      this.selectedSites.push(siteOnItem);
    }
    this.searchScenarioForm.patchValue({ searchSiteName: this.selectedSites });
  }

  // 2. サイト
  siteOnSelectAll(siteOnItems: any) {
    this.selectedSites = siteOnItems;
    this.searchScenarioForm.patchValue({ searchSiteName: this.selectedSites });
  }

  // 3. サイト
  siteOnItemUnSelect(siteOnItem: any) {
    this.selectedSites = this.selectedSites.filter(site => site.id !== siteOnItem.id);
    this.searchScenarioForm.patchValue({ searchSiteName: this.selectedSites });
  }

  // 4. サイト
  clearSiteSelections() {
    this.selectedSites = [];
    this.searchScenarioForm.patchValue({ searchSiteName: this.selectedSites });
  }

  // 1.カテゴリー
  categoryOnItemSelect(categoryOnItem: any) {
    const alreadyCategorySelected = this.selectedCategories.some(cat => cat.id === categoryOnItem.id);
    if (!alreadyCategorySelected) {
      this.selectedCategories.push(categoryOnItem);
    }
    this.searchScenarioForm.patchValue({ searchCategory: this.selectedCategories });
  }

  // 2.カテゴリー
  categoryOnSelectAll(categoryOnItems: any) {
    this.selectedCategories = categoryOnItems;
    this.searchScenarioForm.patchValue({ searchCategory: this.selectedCategories });
  }

  // 3.カテゴリー
  categoryOnItemUnSelect(categoryOnItem: any) {
    this.selectedCategories = this.selectedCategories.filter(cat => cat.id !== categoryOnItem.id);
    this.searchScenarioForm.patchValue({ searchCategory: this.selectedCategories });
  }

  // 4.カテゴリー
  clearCategorySelections() {
    this.selectedCategories = [];
    this.searchScenarioForm.patchValue({ searchCategory: this.selectedCategories });
  }

  /**
   * シナリオ一覧に表示するデータを取得する
   */
  searchScenarioConfigs(): void {
    // ローディングのエフェクト開始
    this.isLoading = true;

    const searchScenarioName = this.searchScenarioForm.value.searchScenarioName;
    const searchGroupId = this.searchScenarioForm.value.searchGroupId;
    const searchSiteName = this.searchScenarioForm.value.searchSiteName;
    const searchCategory = this.searchScenarioForm.value.searchCategory;
    const searchRunscenario = this.searchScenarioForm.value.searchRunscenario;

    // Make the API call with the extracted IDs
    this.configService
      .getScenarioConfigs(
        searchScenarioName,
        searchGroupId,
        searchSiteName,
        searchCategory,
        searchRunscenario,
      )
      .subscribe((response) => {
        this.scenarioConfigs = response;
        // ローディングのエフェクト終了
        this.isLoading = false;
      }, (error) => {
        // ローディングのエフェクト終了
        this.isLoading = false;
      });
  }

  /**
   * シナリオ登録画面表示
   * @param addScenario
   */
  openAddScenario(addScenario): void {
    this.modalDisabled = true;
    this.isSubmitted = false;
    this.modalMessage = '';
    this.addScenarioData = new FormData();
    this.plan_id = this.userGroups[0].plan_id;

    // シナリオ登録画面フォーム　Validation設定
    const pattern = /^(http(s)?:\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}.*/;
    this.addScenarioForm = this.formBuilder.group({
      addGroupId: [this.userGroups[0].id, [Validators.required]],
      addScenarioName: ['', [Validators.required, Validators.maxLength(255)]],
      addSiteName: ['', [Validators.maxLength(255)]],
      addCategory: ['', [Validators.maxLength(255)]],
      addPriority: [''],
      addSiteURL: ['', [Validators.maxLength(255),
        Validators.pattern(pattern)]],
      addScenarioFileDesktop: [''],
      addScenarioFileMobile: [''],
      addRunSelect: ['0', [Validators.required]],
      addMonitorSelect: ['0'],
    });

    this.modalService.open(addScenario);
    this.modalDisabled = false;
  }

  /**
   * シナリオ編集画面表示
   * @param editScenario
   */
  openEditScenario(scenarioId, editScenario): void {
    this.modalDisabled = true;
    this.isSubmitted = false;
    this.modalMessage = '';
    this.addScenarioData = new FormData();

    // シナリオ編集画面に必要な情報を取得する
    this.configService.getScenarioConfig(scenarioId).subscribe((response) => {
      if (response == null) {
        alert('既に削除済みのシナリオです。');
        this.searchScenarioConfigs();
      } else {
        // シナリオ編集画面フォーム　Validation設定
        // check group id and plan id
        const group = response.group_id;
        const groupdata = this.userGroups.filter( function (gp) { return Number(gp.id) === Number(group); });
        this.plan_id = groupdata[0].plan_id;
        const pattern = /^(http(s)?:\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}.*/;
        //
        this.editScenarioForm = this.formBuilder.group({
          editScenarioId: [response.id],
          editGroupId: [response.group_id, [Validators.required]],
          editScenarioName: [
            response.scenario_name,
            [Validators.required, Validators.maxLength(255)],
          ],
          editSiteName: [response.site_name, [Validators.maxLength(255)]],
          editCategory: [response.category, [Validators.maxLength(255)]],
          editPriority: [response.priority], // , [Validators.required]
          editScenarioFileDesktop: [''],
          editSiteURL: [(response.monitoring_type.toString() === '1' ? '' : response.site_url),
            [Validators.maxLength(255), Validators.pattern(pattern)]],
          editMonitorSelect: [response.monitoring_type.toString()],
          uploadScenarioFileDesktop: [
            (response.monitoring_type.toString() === '0' ? null : response.scenario_file_desktop),
          ],
          editScenarioFileMobile: [''],
          uploadScenarioFileMobile: [
            (response.monitoring_type.toString() === '0' ? null : response.scenario_file_mobile),
          ],
          editRunSelect: [
            response.run_scenario.toString(),
            [Validators.required],
          ],
        });

        this.modalService.open(editScenario);
      }

      this.modalDisabled = false;
    });
  }

  openInstantQueueConfirm(scenarioId, instantQueueCreateConfirm): void {
    this.scenarioCreationId = scenarioId;
    this.modalService.open(instantQueueCreateConfirm);
  }

  /**
   * シナリオ実行結果確認画面表示
   * @param scenarioId
   * @param readRunResult
   */
  openReadRunResult(scenarioId, readRunResult): void {
    this.modalDisabled = true;
    this.isSubmitted = false;
    this.modalMessage = '';
    this.addScenarioData = new FormData();
    this.runResults = [];

    // 実行結果確認画面検索フォーム　Validation設定
    this.searchRunResultForm = this.formBuilder.group({
      searchScenarioId: [scenarioId],
      startRunResultDay: [''],
      endRunResultDay: [''],
      showFailure: [true],
    });

    // シナリオ実行結果確認画面に必要な情報を取得する
    this.addScenarioData.append('scenarioId', this.searchScenarioId.value);
    this.addScenarioData.append('showFailure', this.showFailure.value);
    this.configService
      .getScenarioRunResult(this.addScenarioData)
      .subscribe((response) => {
        this.runResults = response;
        this.modalService.open(readRunResult);
        this.modalDisabled = false;
      });
  }

  /**
   * シナリオ設定の作製終わった後にすぐキューを作製する機能
   * @param scenarioId
   */
  createInstantQueue(scenarioId, instantQueueCreate): void {
    this.modalDisabled = true;
    this.isSubmitted = false;
    this.modalMessage = '';
    this.createInsantQueueResults = {
      success: {
        message: '',
        log: '',
      },
    };

    this.isLoading = true;
    this.configService.createInstantQueue(scenarioId).subscribe((response) => {
      this.createInsantQueueResults = response;
      this.modalService.open(instantQueueCreate);
      this.modalMessage = response.success.message;
      this.isLoading = false;
      this.modalDisabled = false;
    });
  }

  /**
   * シナリオ登録画面　入力したファイルをフォームデータに設定する
   * @param key
   * @param fileList
   */
  setFile(key, fileList: any): void {
    if (fileList.length <= 0) {
      return;
    }

    const file = fileList[0];
    this.addScenarioData.append(key, file, file.name);
  }

  /**
   * ファイル名から拡張子を取得する
   * @param fileName
   */
  getExt(fileName: string): string {
    const pos = fileName.lastIndexOf('.');
    if (pos === -1) return '';
    return fileName.slice(pos + 1);
  }

  /**
   * シナリオ登録画面　登録ボタン押下処理
   */
  onAddScenarioSubmit(): boolean {
    this.submitDisabled = true;
    this.isSubmitted = true;

    // バリデーションのチェックに違反する場合は処理を中断する
    if ( this.plan_id === 3 || Number(this.addMonitorSelect.value) === 0) {
      // free user
      if (
        this.addScenarioForm.invalid
      ) {
        this.modalMessage = '入力内容が正しくありません。';
        this.submitDisabled = false;
        return false;
      }
    }else {
      // paid user
      if (
        this.addScenarioForm.invalid ||
        (!this.addScenarioFileDesktop.value &&
          !this.addScenarioFileMobile.value) ||
        (this.addScenarioFileDesktop.value &&
          this.getExt(this.addScenarioFileDesktop.value) !== 'js') ||
        (this.addScenarioFileMobile.value &&
          this.getExt(this.addScenarioFileMobile.value) !== 'js')
      ) {
        this.modalMessage = '入力内容が正しくありません。';
        this.submitDisabled = false;
        return false;
      }
    }
    this.addScenarioData.append('addGroupId', this.addGroupId.value); // グループ
    this.addScenarioData.append('addScenarioName', this.addScenarioName.value); // シナリオ名
    this.addScenarioData.append('addSiteName', this.addSiteName.value); // サイト名
    this.addScenarioData.append('addRunSelect', this.addRunSelect.value); // シナリオ実行
    this.addScenarioData.append('addPlan', this.plan_id); // plan_id
    if (this.plan_id === 3) {
      this.addScenarioData.append('addSiteURL', this.addSiteURL.value); // サイトURL
    }else {
      // paid user
      this.addScenarioData.append('addCategory', this.addCategory.value); // カテゴリー
      this.addScenarioData.append('addPriority', this.addPriority.value); // 測定頻度
      this.addScenarioData.append('addMonitor', this.addMonitorSelect.value); // monitor type
      if (Number(this.addMonitorSelect.value) === 0) {
        this.addScenarioData.append('addSiteURL', this.addSiteURL.value); // サイトURL
      }
    }

    // フォームデータをサーバに送信する
    this.configService
      .addScenarioConfigs(this.addScenarioData)
      .subscribe((response) => {
        if (response.errors) {
          this.modalMessage = response.errors.message;
        } else {
          // サイト名とカテゴリーを更新
          this.getScenarioOptions();
          // シナリオ一覧をリロードする
          this.searchScenarioConfigs();
          // モーダルを閉じる
          this.modalService.dismissAll('Submit Success');
        }
        this.submitDisabled = false;
      });
  }

  /**
   * シナリオ編集画面　更新ボタン押下処理
   */
  onEditScenarioSubmit(): boolean {
    this.submitDisabled = true;
    this.isSubmitted = true;

    // バリデーションのチェックに違反する場合は処理を中断する
    if (
      this.editScenarioForm.invalid ||
      (this.editScenarioFileDesktop.value &&
        this.getExt(this.editScenarioFileDesktop.value) !== 'js') ||
      (this.editScenarioFileMobile.value &&
        this.getExt(this.editScenarioFileMobile.value) !== 'js')
    ) {
      this.modalMessage = '入力内容が正しくありません。';
      this.submitDisabled = false;
      return false;
    }

    // 入力内容をフォームデータに設定する
    this.addScenarioData.append('editScenarioId', this.editScenarioId.value); // シナリオID
    this.addScenarioData.append('editGroupId', this.editGroupId.value); // グループ
    this.addScenarioData.append(
      'editScenarioName',
      this.editScenarioName.value,
    ); // シナリオ名
    this.addScenarioData.append('editSiteName', this.editSiteName.value); // サイト名
    this.addScenarioData.append('editRunSelect', this.editRunSelect.value); // シナリオ実行
    this.addScenarioData.append('editPlan', this.plan_id); // plan id
    // update by planid and monitor type
    if (this.plan_id === 3) {
      this.addScenarioData.append('editSiteURL', this.editSiteURL.value); // サイトURL
    }else {
      this.addScenarioData.append('editCategory', this.editCategory.value); // カテゴリー
      this.addScenarioData.append('editPriority', this.editPriority.value); // 測定頻度
      this.addScenarioData.append('editMonitor', this.editMonitorSelect.value); // monitor type
      if (Number(this.editMonitorSelect.value) === 0) {
        this.addScenarioData.append('editSiteURL', this.editSiteURL.value); // サイトURL
      }
    }
    // フォームデータをサーバに送信する
    this.configService
      .editScenarioConfigs(this.addScenarioData)
      .subscribe((response) => {
        if (response.errors) {
          this.modalMessage = response.errors.message;
        } else {
          if (response.alert) {
            // レスポンスにアラートの内容がある場合、アラートを表示してモーダルを閉じる
            alert(response.alert.message);
          }
          // サイト名とカテゴリーを更新
          this.getScenarioOptions();
          // シナリオ一覧をリロードする
          this.searchScenarioConfigs();
          // モーダルを閉じる
          this.modalService.dismissAll('Submit Success');
        }
        this.submitDisabled = false;
      });

    this.isSubmitted = false;
  }

  /**
   * シナリオ編集画面　削除ボタン押下処理
   */
  deleteScenario(): void {
    this.submitDisabled = true;
    if (window.confirm('このシナリオを削除しますか？')) {
      this.configService
        .deleteScenario(this.editScenarioId.value)
        .subscribe((response) => {
          if (response.alert) {
            // レスポンスにアラートの内容がある場合、アラートを表示してモーダルを閉じる
            alert(response.alert.message);
          }
          // サイト名とカテゴリーを更新
          this.getScenarioOptions();
          // シナリオ一覧をリロードする
          this.searchScenarioConfigs();
          // モーダルを閉じる
          this.modalService.dismissAll('Submit Success');
          this.submitDisabled = false;
        });
    } else {
      this.submitDisabled = false;
    }
  }

  /**
   * change group and check plan type for show url or both
   */
  onChangeGroupId(): void {
    /**
     * get groupid
     * const plan = this.selectedViewPlans[this.editPlanId.value];
     */
    const group = this.addGroupId.value;
    const groupdata = this.userGroups.filter( function (gp) { return Number(gp.id) === Number(group); });
    this.plan_id = groupdata[0].plan_id;
  }

  /**
   * change group and check plan type for show url or both in edit
   */
  onChangeEditGroupId(): void {
    /**
     * get groupid
     * const plan = this.selectedViewPlans[this.editPlanId.value];
     */
    const group = this.editGroupId.value;
    const groupdata = this.userGroups.filter( function (gp) { return Number(gp.id) === Number(group); });
    this.plan_id = groupdata[0].plan_id;
  }

  /**
   * 実行結果確認画面　検索ボタン押下処理
   */
  onSearchRunResult(): void {
    this.modalMessage = '';

    // 入力内容をフォームデータに設定する
    this.addScenarioData.append('scenarioId', this.searchScenarioId.value);

    const startDay = this.startRunResultDay.value;
    if (startDay) {
      if (Object.prototype.toString.call(startDay) === '[object Object]') {
        this.addScenarioData.append(
          'startRunResultDay',
          startDay.year + '-' + startDay.month + '-' + startDay.day,
        );
      } else {
        // 日付が正しく取得できない場合はエラーを表示して検索を中断する
        this.modalMessage = '入力内容が正しくありません。';
        return;
      }
    }

    const endDay = this.endRunResultDay.value;
    if (endDay) {
      if (Object.prototype.toString.call(endDay) === '[object Object]') {
        this.addScenarioData.append(
          'endRunResultDay',
          endDay.year + '-' + endDay.month + '-' + endDay.day,
        );
      } else {
        // 日付が正しく取得できない場合はエラーを表示して検索を中断する
        this.modalMessage = '入力内容が正しくありません。';
        return;
      }
    }

    this.addScenarioData.append('showFailure', this.showFailure.value);

    // シナリオ実行結果確認画面に必要な情報を取得する
    this.configService
      .getScenarioRunResult(this.addScenarioData)
      .subscribe((response) => {
        this.runResults = response;
      });
  }

  /**
   * getter 検索エリア
   */
  // シナリオ名
  get searchScenarioName() {
    return this.searchScenarioForm.get('searchScenarioName');
  }

  // グループ
  get searchGroupId() {
    return this.searchScenarioForm.get('searchGroupId');
  }

  // サイト名
  get searchSiteName() {
    return this.searchScenarioForm.get('searchSiteName');
  }

  // カテゴリー
  get searchCategory() {
    return this.searchScenarioForm.get('searchCategory');
  }

  // シナリオ実行
  get searchRunscenario() {
    return this.searchScenarioForm.get('searchRunscenario');
  }

  /**
   * getter シナリオ登録画面
   */
  // グループ
  get addGroupId() {
    return this.addScenarioForm.get('addGroupId');
  }

  // シナリオ名
  get addScenarioName() {
    return this.addScenarioForm.get('addScenarioName');
  }

  // サイト名
  get addSiteName() {
    return this.addScenarioForm.get('addSiteName');
  }

  // カテゴリー
  get addCategory() {
    return this.addScenarioForm.get('addCategory');
  }

  // 測定頻度
  get addPriority() {
    return this.addScenarioForm.get('addPriority');
  }

  // サイトURL
  get addSiteURL() {
    return this.addScenarioForm.get('addSiteURL');
  }

  // シナリオファイル　デスクトップ
  get addScenarioFileDesktop() {
    return this.addScenarioForm.get('addScenarioFileDesktop');
  }

  // シナリオファイル　モバイル
  get addScenarioFileMobile() {
    return this.addScenarioForm.get('addScenarioFileMobile');
  }

  // シナリオ実行
  get addRunSelect() {
    return this.addScenarioForm.get('addRunSelect');
  }

  // for monitor type
  get addMonitorSelect() {
    return this.addScenarioForm.get('addMonitorSelect');
  }

  /**
   * getter シナリオ編集画面
   */
  // シナリオID
  get editScenarioId() {
    return this.editScenarioForm.get('editScenarioId');
  }

  // グループ
  get editGroupId() {
    return this.editScenarioForm.get('editGroupId');
  }

  // シナリオ名
  get editScenarioName() {
    return this.editScenarioForm.get('editScenarioName');
  }

  // サイト名
  get editSiteName() {
    return this.editScenarioForm.get('editSiteName');
  }

  // カテゴリー
  get editCategory() {
    return this.editScenarioForm.get('editCategory');
  }

  // 測定頻度
  get editPriority() {
    return this.editScenarioForm.get('editPriority');
  }

  // for edit Monitor type
  get editMonitorSelect() {
    return this.editScenarioForm.get('editMonitorSelect');
  }

  // サイトURL
  get editSiteURL() {
    return this.editScenarioForm.get('editSiteURL');
  }

  // シナリオファイル　デスクトップ
  get editScenarioFileDesktop() {
    return this.editScenarioForm.get('editScenarioFileDesktop');
  }

  // アップロード済シナリオファイル　デスクトップ
  get uploadScenarioFileDesktop() {
    return this.editScenarioForm.get('uploadScenarioFileDesktop');
  }

  // シナリオファイル　モバイル
  get editScenarioFileMobile() {
    return this.editScenarioForm.get('editScenarioFileMobile');
  }

  // アップロード済シナリオファイル　モバイル
  get uploadScenarioFileMobile() {
    return this.editScenarioForm.get('uploadScenarioFileMobile');
  }

  // シナリオ実行
  get editRunSelect() {
    return this.editScenarioForm.get('editRunSelect');
  }

  /**
   * getter 検索エリア
   */
  // シナリオID
  get searchScenarioId() {
    return this.searchRunResultForm.get('searchScenarioId');
  }

  // 表示開始日
  get startRunResultDay() {
    return this.searchRunResultForm.get('startRunResultDay');
  }

  // 表示終了日
  get endRunResultDay() {
    return this.searchRunResultForm.get('endRunResultDay');
  }

  // 失敗のみ表示
  get showFailure() {
    return this.searchRunResultForm.get('showFailure');
  }

  // コンポーネントを破棄する時にunsubscribeする
  ngOnDestroy(): void {
    this.subscriptions.forEach(function (value) {
      value.unsubscribe();
    });

    this.subscriptions = [];
  }
}
